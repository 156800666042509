import React, { useState, useRef, useEffect } from "react";
import { graphql } from "gatsby";
import { v4 as uuidv4 } from 'uuid';
import TodoList from "../../components/Tools/TodoList/TodoList.js";
import "../../components/Tools/TodoList/TodoList.scss";
import Page from "../../components/Page";
import { GatsbyImage } from "gatsby-plugin-image"

const LOCAL_STORAGE_KEY = "todoApp.todos";

export default function Todolist({data}) {
  const [todos, setTodos] = useState([]);
  const todoNameRef = useRef();

  useEffect(() => {
    window.scrollTo(0, 0);
    const storedTodos =
      typeof window !== "undefined" &&
      JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEY));
    if (storedTodos) setTodos(storedTodos);
  }, []);

  useEffect(() => {
    localStorage.setItem(LOCAL_STORAGE_KEY, JSON.stringify(todos));
  }, [todos]);

  function toggleTodo(id) {
    const newTodos = [...todos];
    const todo = newTodos.find((todo) => todo.id === id);
    todo.complete = !todo.complete;
    setTodos(newTodos);
  }

  function handleAddTodo(e) {
    e.preventDefault();
    const name = todoNameRef.current.value;
    if (name === "") return;
    setTodos((prevTodos) => {
      return [...prevTodos, { id: uuidv4(), name: name, complete: false }];
    });
    todoNameRef.current.value = null;
  }

  function handleClearTodos() {
    const newTodos = todos.filter((todo) => !todo.complete);
    setTodos(newTodos);
  }

  const image = data.image.childImageSharp.gatsbyImageData;
  const title = "En enkel att-göra-lista"

  return (
    <>
      <Page title={title} description="En enkel att-göra-lista för att hålla koll på alla dina uppgift som du behöver göra.">
        <div className="container px-5 py-6 mx-auto">
          <article className="blogPost">
            {image && <GatsbyImage image={image} className="mb-5 rounded-xl" alt="spargris" />}
            <h1 className="font-bold text-4xl md:text-5xl text-gray-900 leading-tight mb-8">{title}</h1>
            <p>
              Genom att använda dig av en todolist ser du till att alla de
              sakerna som du behöver göra är nerskrivna vilket i sin tur gör att
              du inte kommer glömma bort dem. Genom att även prioritera dina
              uppgifter kan du på ett smidigt sätt lägga upp en plan som säger i
              vilken ordning som du bör utföra dina uppgifter. På så sätt kan du
              få gjort det viktigaste först.
            </p>
            <br />
            <div className="content-todopage">
              <div className="todoList">
                <form onSubmit={handleAddTodo}>
                  <h3>Din todolist</h3>
                  <div className="upperInput">
                    <input
                      className="inputTodo"
                      ref={todoNameRef}
                      placeholder="Vad behöver du göra?"
                      type="text"
                    />
                    <button className="btnCreateTodo" type="submit">
                      Skapa
                    </button>
                  </div>
                </form>

                <div className="todoLeft">
                  <div>
                    {todos.filter((todo) => !todo.complete).length} kvar att
                    göra
                  </div>
                </div>
                <TodoList todos={todos} toggleTodo={toggleTodo} />
                <button onClick={handleClearTodos} className="clearDoneBtn">
                  Ta bort färdiga!
                </button>
              </div>
              <br />
            </div>
            <br />
            <br />
          </article>
        </div>
    </Page>
    </>
  );
}


export const query = graphql`
  query {
    image: file(relativePath: { eq: "programmer.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: CONSTRAINED)
      }
    }
  }
`;
