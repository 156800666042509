import React from 'react';

export default function Todo({ todo, toggleTodo }) {
  function handleTodoClick() {
    toggleTodo(todo.id)
  }
  
  return (
    <div className="todoItem">  
        <div className="todoText">
        {todo.name}
        </div> 
        <span>
        <input type="checkbox" checked={todo.complete} onChange={handleTodoClick} className="checkbox"/>
        </span>
    </div>
  )
}